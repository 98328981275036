<template>
  <div
    class="loginPanel"
    element-loading-text="拼命加载中"
    element-loading-spinner="a-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="bg" id="large-header">
      <canvas id="demo-canvas" width="606" height="754" style></canvas>
    </div>
    <div
      class="login"
      element-loading-text="loading ..."
      element-loading-spinner="a-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="loader" v-if="spinStatus">
        <div class="dot_box">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <p class="loader_tip">扫码授权成功！正在查询用户信息。</p>
      </div>
      <div class="login-box" v-if="loginType && !spinStatus">
        <p class="title"><a-icon type="login" />系统登录</p>
        <a-alert v-if="loginBind" type="warning" style="margin-top: 12px">
          <p slot="message" class="warning_bind_tips">
            <a-avatar :src="userMessage.avatar" :size="48" style="margin-right: 8px" />
            <span>{{ userMessage.employee_name }}{{ welcomeday }}好，请绑定已有帐号。</span>
          </p>
        </a-alert>
        <div class="content">
          <a-form-model :model="entity" :rules="rules" ref="form" class="form-panel">
            <a-form-model-item prop="username">
              <a-input placeholder="请输入用户名" size="large" v-model="entity.username">
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input-password placeholder="请输入密码" size="large" v-model="entity.password">
                <a-icon slot="prefix" type="lock" />
              </a-input-password>
            </a-form-model-item>
            <div>
              <a-checkbox v-model="entity.rememberMe" style="color: gray; margin-bottom: 8px">
                <span>记住我</span>
              </a-checkbox>
            </div>
            <div style="margin-bottom: 12px">
              <a-button
                type="primary"
                html-type="submit"
                class="login-button"
                size="large"
                @click="login"
                :loading="loading"
                >{{ btnCode ? '登录并绑定' : '马上登录' }}</a-button
              >
            </div>
          </a-form-model>
          <div class="otherLogin">
            其他登录方式<a @click="cutLoginType(1)"><img src="@/assets/compony-weixin.png" class="compony-wchat" /></a>
          </div>
          <div class="formAction"></div>
        </div>
      </div>
      <!-- 二维码登录 -->
      <div class="wechat-code" v-if="!loginType">
        <div id="wx_login" class="wechat-img"></div>
        <!-- <div class="wechat-uat">
          <a @click="cutLoginType(0)">帐号密码登录</a>
        </div> -->
      </div>
    </div>
    <div class="footer">
      <center>
        <span>
          推荐分辨率：1920*1080;推荐使用
          <a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">Chrome浏览器</a>
        </span>
      </center>
    </div>
  </div>
</template>

<script>
import { KeyCode, bus, auth } from '@/utils';
import JsEncrypt from 'jsencrypt';
import buryEvent from '@/utils/buryEvent.js';
const qs = require('qs');

export default {
  name: 'login',
  components: {},
  props: {
    skin: {
      type: String,
      default() {
        return 'default';
      },
    },
  },
  data() {
    return {
      loading: false,
      stores: [],
      publicKey: '',
      storeId: null,
      entity: {
        username: '',
        password: '',
        rememberMe: true,
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
      loginType: false,
      spinStatus: false, // 扫码登录loading状态
      loginBind: false, // 提示绑定登录
      userMessage: {},
      btnCode: false,
    };
  },
  created() {
    // var vm = this;
    // document.onkeydown = function(e) {
    //   var key = window.event.keyCode;
    //   if (key == 13) {
    //     vm.login();
    //   }
    // }
    this.welcomeday = this.$moment().format('a');
    if (this.$route.query.ticket) {
      this.spinStatus = true;
      this.btnCode = true;
      let params = {
        grant_type: 'qy_wechat',
        qy_wechat: this.$route.query.ticket,
      };
      this.sendLogin(params);
    } else {
      // this.$router.push({ query: {} })
      this.btnCode = false;
    }
  },
  mounted() {
    const { debug } = this.$route.query;

    if (debug) {
      this.loginType = true;

      try {
        let p = auth.getMe();
        if (p) {
          this.entity.username = p.username;
          this.entity.password = p.password;
        }
      } catch (err) {}
    } else {
      this.getWchatCode();
    }

    this.loginInit();
    // this.$api.AppService.currentCorpStores().then((_rs)=>{
    //   let rs = (_rs||[]).filter(r=>!r.deleted&&r.status);
    //   this.stores =rs;
    //   if(rs.length>0){
    //     this.storeId = rs[0].id;
    //   }
    // })

    var width,
      height,
      largeHeader,
      canvas,
      ctx,
      points,
      target,
      animateHeader = true;

    // Main
    initHeader();
    initAnimation();
    //addListeners();

    function initHeader() {
      width = window.innerWidth;
      height = window.innerHeight;
      target = { x: width / 2, y: height / 2 };
      target = { x: 300, y: 300 };
      largeHeader = document.getElementById('large-header');
      largeHeader.style.height = height + 'px';

      canvas = document.getElementById('demo-canvas');
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');

      // create points
      points = [];
      for (var x = 0; x < width; x = x + width / 20) {
        for (var y = 0; y < height; y = y + height / 20) {
          var px = x + (Math.random() * width) / 20;
          var py = y + (Math.random() * height) / 20;
          var p = { x: px, originX: px, y: py, originY: py };
          points.push(p);
        }
      }

      // for each point find the 5 closest points
      for (var i = 0; i < points.length; i++) {
        var closest = [];
        var p1 = points[i];
        for (var j = 0; j < points.length; j++) {
          var p2 = points[j];
          if (!(p1 == p2)) {
            var placed = false;
            for (var k = 0; k < 5; k++) {
              if (!placed) {
                if (closest[k] == undefined) {
                  closest[k] = p2;
                  placed = true;
                }
              }
            }

            for (var k = 0; k < 5; k++) {
              if (!placed) {
                if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                  closest[k] = p2;
                  placed = true;
                }
              }
            }
          }
        }
        p1.closest = closest;
      }

      // assign a circle to each point
      for (var i in points) {
        var c = new Circle(points[i], 2 + Math.random() * 2, 'rgba(255,255,255,0.3)');
        points[i].circle = c;
      }
    }

    // Event handling
    function addListeners() {
      if (!('ontouchstart' in window)) {
        window.addEventListener('mousemove', mouseMove);
      }
      window.addEventListener('scroll', scrollCheck);
      window.addEventListener('resize', resize);
    }

    function mouseMove(e) {
      var posx = 0,
        posy = 0;
      if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
      } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }
      target.x = posx;
      target.y = posy;
    }

    function scrollCheck() {
      if (document.body.scrollTop > height) animateHeader = false;
      else animateHeader = true;
    }

    function resize() {
      width = window.innerWidth;
      height = window.innerHeight;
      largeHeader.style.height = height + 'px';
      canvas.width = width;
      canvas.height = height;
    }

    // animation
    function initAnimation() {
      animate();
      for (var i in points) {
        shiftPoint(points[i]);
      }
    }

    function animate() {
      if (animateHeader) {
        ctx.clearRect(0, 0, width, height);
        for (var i in points) {
          // detect points in range
          if (Math.abs(getDistance(target, points[i])) < 4000) {
            points[i].active = 0.3;
            points[i].circle.active = 0.6;
          } else if (Math.abs(getDistance(target, points[i])) < 20000) {
            points[i].active = 0.1;
            points[i].circle.active = 0.3;
          } else if (Math.abs(getDistance(target, points[i])) < 40000) {
            points[i].active = 0.02;
            points[i].circle.active = 0.1;
          } else {
            points[i].active = 0;
            points[i].circle.active = 0;
          }

          drawLines(points[i]);
          points[i].circle.draw();
        }
      }
      requestAnimationFrame(animate);
    }

    function shiftPoint(p) {}

    // Canvas manipulation
    function drawLines(p) {
      if (!p.active) return;
      for (var i in p.closest) {
        ctx.beginPath();
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(p.closest[i].x, p.closest[i].y);
        ctx.strokeStyle = 'rgba(156,217,249,' + p.active + ')';
        ctx.stroke();
      }
    }

    function Circle(pos, rad, color) {
      var _this = this;

      // constructor
      (function () {
        _this.pos = pos || null;
        _this.radius = rad || null;
        _this.color = color || null;
      })();

      this.draw = function () {
        if (!_this.active) return;
        ctx.beginPath();
        ctx.arc(_this.pos.x, _this.pos.y, _this.radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'rgba(156,217,249,' + _this.active + ')';
        ctx.fill();
      };
    }

    // Util
    function getDistance(p1, p2) {
      return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }
  },
  methods: {
    //登录，登录成功后获取厂牌
    async login() {
      const { username, password, rememberMe } = this.entity;

      let password2 = await this.encrypt(password);

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let params = {
          username,
          password: password2,
          rememberMe,
          grant_type: 'password',
        };
        if (this.$route.query.ticket) {
          params.qy_wechat = this.$route.query.ticket;
        }

        this.loading = true;
        this.$api.AppService.login(params)
          .then((res) => {
            this.loading = false;
            if (res.errcode == 5002) {
              this.$message.error(res.errmsg);
              return false;
            }
            auth.setToken({
              access_token: res.data.token,
              expires_in: res.data.expiresIn,
            });
            if (rememberMe) {
              auth.rememberMe({
                username: username,
                password: password,
              });
            } else {
              auth.clearMe();
            }
            bus.$emit('app:logged');
            let action = this.btnCode ? 'bind-wecom' : 'password';
            buryEvent('login', action, `${username}`);
          })
          .catch(({ error, disableDefaultHandler }) => {
            disableDefaultHandler();
            this.loading = false;
            this.$message.error(error.errmsg);
            // if (error.code === "usernameWrong"||error.code === "passwordWrong") {
            //   this.$message.error(`用户名或密码错`);
            // } else {
            //   this.$message.error("登录异常:" + error.message);
            // }
          });
      });
    },
    async loginInit() {
      return this.$api.AppService.loginInit().then((r) => {
        this.publicKey = r.publicKey;
      });
    },
    //重置
    reset() {
      this.account.account = '';
      this.account.password = '';
      $('#account').focus();
    },
    async encrypt(obj) {
      let encrypt = new JsEncrypt();
      !this.publicKey && (await this.loginInit());
      encrypt.setPublicKey(this.publicKey);
      return encrypt.encrypt(obj);
    },
    // 获取扫码授权相关参数
    getWchatCode() {
      let url = window.location.href;
      console.log('url:', url);
      // 如果有重定向参数
      if (url.indexOf('?') >= 0) {
        const params = qs.parse(url.split('?')[1]);
        console.log('params:', params);
        url = url.split('?')[0];
        if (params.redirect) {
          sessionStorage.redirectUrl = params.redirect;
        }
      } else {
        sessionStorage.redirectUrl = '';
      }
      // const {href} = window.WwLogin
      let data = {
        redirect_uri: url,
      };
      this.$api.AppService.wchatCode(data)
        .then((res) => {
          if (res.code == 200) {
            const { agentid, appid, redirect_uri, stat } = res.data;
            // console.log('window.WwLogin',window.WwLogin)
            window.WwLogin({
              id: 'wx_login',
              appid, //appid
              agentid, //agentid
              redirect_uri, //回调地址，注意回调地址需要进行urlencode
              state: stat, //用于保持请求和回调的状态，授权请求后原样带回给企业。该参数可用于防止csrf攻击,参数非必填
              // "href" : "XXXX",//自定义样式链接，企业可根据实际需求覆盖默认样式，参数非必填
            });
          } else {
            this.$message.error('获取二维码参数失败');
            return;
          }
        })
        .catch((res) => {
          this.$message.error('获取二维码参数失败');
          return;
        });
    },
    // 切换登录方式
    cutLoginType(value) {
      if (value == 0) {
        this.loginType = true;
        buryEvent('login', 'toggle-to-wecom');
      } else {
        this.btnCode = false;
        this.loginBind = false;
        this.$router.push({ query: {} });
        this.getWchatCode();
        this.loginType = false;
        buryEvent('login', 'toggle-to-password');
      }
    },
    // 报错提示
    requestFailed(err) {
      let message = ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试';
      if (err.code) {
        message = err.message;
      }
      if (err.code == 50101005) {
        this.loginBind = true;
        this.loginType = true;
        this.userMessage = err.data;
        this.$notification['warning']({
          message: '注意',
          description: message,
          duration: 4,
        });
      } else {
        this.isLoginError = true;
        this.$notification['error']({
          message: '错误',
          description: message,
          duration: 4,
        });
      }

      this.tipContent = message;
    },
    // 企微登录
    sendLogin(params) {
      this.$api.AppService.login(params).then((res) => {
        // console.log(res)
        this.spinStatus = false;
        if (res.code == 200) {
          auth.setToken({
            access_token: res.data.token,
            expires_in: res.data.expires_in,
          });
          bus.$emit('app:logged');
        } else {
          this.requestFailed(res);
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/less" lang="less">
.loginPanel {
  height: 100%;
  width: 100%;
  position: absolute;
  .bg {
    background-position: top center;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #2f5d87;
  }
}
.loginPanel .logo {
  height: 25px;
  margin-top: 8px;
}
.title {
  color: #1890ff;
  font-size: 14px;
  font-weight: 200;
}

.login {
  // width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  // position:absolute;
  // right:200px;
  .title {
    /* background: @purple; */
    //color: #212b37;
    font-size: 14px;
    text-indent: 10px;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 1px solid #d9d9d9;
  }
  .login-content {
    position: relative;
    top: 30px;
    left: 40px;
    width: 300px;
    height: 200px;
  }
  .login-content .form-group {
    position: absolute;
    left: 70px;
    display: block;
    width: 200px;
  }
  .login-content .account {
    top: 34px;
  }
  .login-content .password {
    top: 78px;
  }
  .login-content .form-group input {
    background: #fff;
    width: 200px;
    height: 28px;
    line-height: 28px;
    border: 0;
    outline: none;
  }
  .login-content .form-group select {
    background: #fff;
    width: 200px;
    height: 28px;
    line-height: 28px;
    border: 0;
    outline: none;
  }
  .login-box {
    .title {
      line-height: 40px;
    }
    z-index: 10;
    padding: 12px;
    width: 450px;
    // height: 330px;
    border-radius: 4px;
    box-shadow: 0 0 6px #73bbcf;
    background: #fff;
    .content {
      width: 350px;
      margin: 0 auto;
      padding-top: 30px;
      .form-group {
        display: block;
        line-height: 36px;
        // background: #f0eef1;
        color: #666;
        // border: 1px solid silver;
        //padding-left: 6px;
        input,
        select {
          height: 36px;
          outline: none;
          border: none;
          width: 100%;
          background: #f0eef1;
          text-indent: 40px;
        }
        label {
          display: inline-block;
          min-width: 30px;
          padding: 5px 5px 0px 10px;
          position: absolute;
        }
      }
      .password,
      .account {
        margin-top: 10px;
      }
    }
  }
}
.otherLogin {
  font-size: 16px;
  .compony-wchat {
    width: 26px;
    height: 24px;
    margin-left: 12px;
  }
}
button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 100%;
}
.wechat-code {
  display: flex;
  flex-flow: column;
  width: 450px;
  height: 456px;
  background: #fff;
  z-index: 9;
  text-align: center;
  padding: 16px 0;
}
.wechat-img {
  height: 405px;
}
.wechat-uat {
  text-align: right;
  margin-right: 24px;
  a {
    display: inline-block;
  }
}
.loader {
  position: absolute;
  top: 25%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-25%, -50%, 0);
}
.dot_box {
  display: flex;
  justify-content: center;
}
.dot {
  width: 24px;
  height: 24px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #32aacc;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #64aacc;
}
.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #96aacc;
}
.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #c8aacc;
}
.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #faaacc;
}
@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.loader_tip {
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 600;
  color: #ddd;
}
</style>
<style lang="less" scoped>
.footer {
  width: 100%;
  color: #40798b;
  position: fixed;
  bottom: 30px;
  text-align: center;
  a {
    color: #40798b;
    text-decoration: underline;
  }
}
</style>
